export default [
  {
    header: 'Moduły',
  },
  {
    title: 'Aktualne serwisy',
    icon: 'SettingsIcon',
    route: 'apps-filters',
    onlyForAdmin: false, 
  },
  {
    title: 'Nieaktywne urządzenia',
    icon: 'SettingsIcon',
    route: 'apps-inactive',
    onlyForAdmin: false, 
  },
  {
    title: 'Serwisanci',
    icon: 'UserIcon',
    route: 'apps-servicers',
    onlyForAdmin: false, 
  },
  {
    title: 'Przedstawiciele',
    icon: 'UserIcon',
    route: 'apps-representatives',
    onlyForAdmin: false, 
  },
  {
    title: 'Klienci',
    icon: 'UserIcon',
    route: 'apps-clients',
    onlyForAdmin: false, 
  },
  {
    title: 'Rodzaje serwisów',
    icon: 'SettingsIcon',
    route: 'apps-services',
    onlyForAdmin: false, 
  },
  {
    title: 'Raport',
    icon: 'SettingsIcon',
    route: 'apps-report',
    onlyForAdmin: true, 
  },
  {
    title: 'Użytkownicy',
    icon: 'SettingsIcon',
    route: 'apps-users',
    onlyForAdmin: true, 
  },
  {
    title: 'Zmień hasło',
    icon: 'SettingsIcon',
    route: 'apps-changepassword',
    onlyForAdmin: false, 
  },
  {
    title: 'Instrukcje obsługi',
    icon: 'SettingsIcon',
    route: 'apps-tutorials',
    onlyForAdmin: false, 
  },
  /*{
    title: 'Historia serwisów',
    icon: 'SettingsIcon',
    route: 'apps-realizedservices',
  },
  {
    title: 'Urządzenia',
    icon: 'SettingsIcon',
    route: 'apps-filters',
  },
  {
    title: 'Naprawy',
    icon: 'SettingsIcon',
    route: 'apps-reparatures',
  },*/
]
